import extraGlobalState from '@/utils/extraGlobalState';
import { defaultI18n, withPopUpI18n } from '@/utils/i18n/context';
import { Button, DateTimePicker, ModalDialog, PureInput as Input, Select, UploadImgText } from 'components';
import { CHECK, ERROR, FLOAT_P_3, FLOAT_P_6 } from 'constants';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
// import moment from 'moment'
import { g7Done, opTips, postJAVA, showInfo, validateFieldsOf } from 'utils';
import driverSug, { driverHeader as driverHeaderOrigin } from 'utils/sug/driver';
import truckSug, { getTruckStateHeader, truckExtHeader } from 'utils/sug/truck';
import { prefixCls } from './index.scss';

const required = bool => (bool ? ' r' : '');

// 司机的header添加考勤字段，因为utils/sug/driver中driverHeader很多地方都在用，不敢直接修改原始值
const driverHeader = [...driverHeaderOrigin, { title: '考勤状态', key: 'attend_flag_desc' }];

class TaskDepart extends PureComponent {
  static propTypes = {
    close: PropTypes.func,
    infos: PropTypes.array,
    formData: PropTypes.object,
    minTruckT: PropTypes.string,
    onSaveSuccess: PropTypes.func,
    i18n: PropTypes.object,
  };

  static defaultProps = {
    i18n: defaultI18n,
  };

  constructor(props) {
    super(props);
    const weightUnit = _.get(window, 'company_setting.weight_unit.value') === 'KG' ? 'kg' : '吨';
    const loadNetWeightField = _.get(window, 'company_setting.pc_departure_operation_field.fields.load_net_weight', {});
    this.state = {
      infos: props.infos || [],
      formData: props.formData,
      startImg: [],
      loadNetWeightField,
      weightUnit,
      weightUnitIsT: weightUnit === '吨',
      confirmLoading: false,
    };
  }

  success = () => {
    this.modal && this.modal.handleHide();
    this.props?.onSaveSuccess?.();
  };

  confirm = async () => {
    const { infos, formData, startImg, weightUnitIsT, loadNetWeightField, confirmLoading } = this.state;
    if (confirmLoading) return;
    const taskDict = this.props.i18n.get('batch', '任务');

    if (!(await validateFieldsOf(this.wrap))) return false;
    if (!formData.truck_t) {
      return showInfo(ERROR, '请选择发车时间');
    }
    if (infos.length === 1 && formData.truck_t && formData.truck_t < formData.min_truck_t) {
      showInfo(ERROR, '发车时间不能早于该节点的到达时间');
      this.wrap.querySelector('.ant-input').click();
      return;
    }
    const req = {
      ...formData,
    };
    if (loadNetWeightField.show && formData.load_net_weight) {
      req.load_net_weight = weightUnitIsT ? formData.load_net_weight * 1000 : formData.load_net_weight;
    }
    req.infos = infos;
    req.imgs = startImg;
    this.setState({ confirmLoading: true });
    const fetchRes = await postJAVA('/cmm-batch/batch/depart', { ...req, is_check: 1 });
    this.setState({ confirmLoading: false });
    opTips({
      resData: fetchRes,
      orderType: taskDict,
      opType: '发车',
      continueCallback: (sucId, cb) => {
        if (sucId && sucId.length) {
          const sucInfos = (infos || []).filter(item => sucId.includes(+item.b_basic_id));
          req.infos = sucInfos;
        }
        postJAVA('/cmm-batch/batch/depart', req).then(res => {
          cb();
          if (res.res.failed_detail.length === 0) {
            showInfo(CHECK, '发车成功');
            this.success();
          }
        });
      },
      sucCallback: this.success,
    });
  };

  selectChange = (type, val) => {
    const formData = { ...this.state.formData };
    if (type === 'truck_t') {
      formData.truck_t = val;
    } else if (type === 'tr_num') {
      formData.truck_id = val.id;
      formData.tr_num = val.tr_num;
      if (val.dr_com_id) {
        formData.dr_id = val.dr_com_id;
        formData.dr_name = val.dr_name;
        formData.dr_phone = val.dr_tel;
      }
    } else if (type === 'tr_num_ext') {
      formData.tr_ext_id = val.id;
      formData.tr_num_ext = val.tr_num;
    } else {
      formData.dr_id = val.id;
      formData.dr_name = val.name;
      formData.dr_phone = val.tel;
      formData.black_set = val.black_set;
    }
    this.setState({ formData });
  };

  inputChange = (e, key) => {
    this.setState({ formData: { ...this.state.formData, [key]: e.target.value } });
  };
  // need_attendance 考勤状态
  truckSug = keyword => truckSug({ keyword, is_tr_header: 1, need_attendance: 1 });
  truckExtSug = keyword => truckSug({ keyword, is_tr_header: 0 });
  driverSug = keyword =>
    driverSug({
      keyword,
      need_attendance: 1,
      // 只查询在职的司机（数据会直接把不在职的司机过滤）
      job_status: [1],
    });
  validateBlackDriver = val => {
    if (val && val.black_set === '1') {
      // showInfo(WARN, '当前司机已被拉黑')
      return { type: 'warn', msg: '当前司机已被拉黑' };
    } else if (val && val.black_set === '2') {
      return '当前司机已被拉黑';
    }
    return '';
  };
  customValidator = {
    dr_phone: () => {
      const { state } = this;
      return this.validateBlackDriver(state.formData);
    },
    dr_name: () => {
      const { state } = this;
      return this.validateBlackDriver(state.formData);
    },
  };
  changeImg = (value, op) => {
    const { startImg = [] } = this.state;
    let newImgs = [...(startImg || [])];
    if (op === 'add') {
      newImgs.push(value);
    } else if (op === 'del') {
      newImgs = newImgs.filter(item => item.name !== value.name);
    }
    this.setState({ startImg: newImgs });
  };
  render() {
    const { infos, formData, batchDict, loadNetWeightField, weightUnit, weightUnitIsT, confirmLoading } = this.state;
    const trNumEnum = [{ tr_num: formData.tr_num }];
    const trNumExtEnum = [{ tr_num: formData.tr_num_ext }];
    const drNameEnum = [{ name: formData.dr_name }];
    const drPhoneEnum = [{ tel: formData.dr_phone }];
    const filedRequired = true;
    const truckStateHeader = getTruckStateHeader(this.props.i18n);
    const newHeader = [
      { key: 'co_pliot_name', title: '副驾司机' },
      { key: 'co_pliot_phone', title: '副驾电话' },
      { key: 'escort_staff', title: '押运员' },
      { key: 'escort_staff_tel', title: '押运员电话' },
      { key: 'attend_flag_desc', title: '考勤状态' },
    ];
    // 把副驾/押运员插入表头里
    const headerArr = truckStateHeader.slice(0, 7).concat(newHeader, truckStateHeader.slice(7));

    const tipEl =
      infos.length > 1 ? (
        <div className="tip">
          <i className="fn-icon-il fn-icon-warn-o" />
          <p>
            您确定要操作批量发车吗？
            <br />
            如为批量操作，所有{batchDict}都将以此发车时间为准
          </p>
        </div>
      ) : (
        <div className="tip">
          <p>请您确认发车信息</p>
        </div>
      );

    const useNewWayEnabled = extraGlobalState.getState('isNewWayEnabled');
    const content = (
      <div ref={r => (this.wrap = r)} className={prefixCls}>
        {tipEl}
        <div className="fields">
          <div>
            <label className={`fn-label${required(filedRequired)}`}>发车时间：</label>
            <DateTimePicker
              defaultValue={formData.truck_t}
              showTime
              onChange={val => this.selectChange('truck_t', val)}
            />
          </div>
          {infos.length === 1 && (
            <div>
              <label className={`fn-label${required(filedRequired)}`}>车牌号：</label>
              <Select
                value={formData.tr_num}
                data={trNumEnum}
                map={false}
                format="tr_num"
                compare="tr_num"
                header={headerArr}
                required={filedRequired}
                onChange={val => this.selectChange('tr_num', val)}
                filter={this.truckSug}
              />
            </div>
          )}
          {infos.length === 1 && (
            <div>
              <label>挂车牌号：</label>
              <Select
                value={formData.tr_num_ext}
                data={trNumExtEnum}
                map={false}
                format="tr_num"
                compare="tr_num"
                header={truckExtHeader}
                onChange={val => this.selectChange('tr_num_ext', val)}
                filter={this.truckExtSug}
              />
            </div>
          )}
          {infos.length === 1 && (
            <div>
              <label className={`fn-label${required(filedRequired)}`}>司机姓名：</label>
              <Select
                value={formData.dr_name}
                data={drNameEnum}
                map={false}
                format="name"
                compare="name"
                header={driverHeader}
                required={filedRequired}
                onChange={val => this.selectChange('dr_name', val)}
                filter={this.driverSug}
                customValidity={this.customValidator.dr_name}
              />
            </div>
          )}
          {infos.length === 1 && (
            <div>
              <label className={`fn-label${required(filedRequired)}`}>司机电话：</label>
              <Select
                value={formData.dr_phone}
                data={drPhoneEnum}
                map={false}
                format="tel"
                compare="tel"
                header={driverHeader}
                required={filedRequired}
                onChange={val => this.selectChange('dr_phone', val)}
                filter={this.driverSug}
                customValidity={this.customValidator.dr_phone}
              />
            </div>
          )}
          {infos.length === 1 && loadNetWeightField.show && (
            <div>
              <label className={`fn-label${required(loadNetWeightField.required)}`}>装车净重：</label>
              <Input
                value={formData.load_net_weight}
                required={loadNetWeightField.required}
                onChange={e => this.inputChange(e, 'load_net_weight')}
                placeholder="请输入"
                pattern={weightUnitIsT ? FLOAT_P_6 : FLOAT_P_3}
              >
                <span className="input-icon unit">{weightUnit}</span>
              </Input>
            </div>
          )}
          {infos.length === 1 && g7Done() && (
            <div>
              <UploadImgText
                isAsyn
                asynReq={{ type: 'receipt' }}
                photoProps
                labelText="提货单照片："
                maxNum={10}
                maxSize={5}
                compress
                fileType="receipt"
                isOss={useNewWayEnabled}
                onChange={this.changeImg}
              />
            </div>
          )}
        </div>
      </div>
    );

    const bottom = (
      <Fragment>
        <Button type="primary" onClick={this.confirm} loading={confirmLoading}>
          确认发车
        </Button>
        <Button onClick={() => this.modal.handleHide()}>取消</Button>
      </Fragment>
    );

    return (
      <ModalDialog
        ref={r => (this.modal = r)}
        content={content}
        bottom={bottom}
        isShow
        isModal
        close={this.props.close}
        title="发车通知"
      />
    );
  }
}
export default withPopUpI18n(TaskDepart);
