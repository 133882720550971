import { Affix } from '@gui/web-react';
import { IconSwap } from '@gui/web-react/icon';
import classnames from 'classnames';
import { CardBox, Icon, Load } from 'components';
import React from 'react';
import { dataAnalyticTrack } from 'utils/dataAnalytic';
import { PaymentRequest } from '../../paymentRequest';
import BiAmountDetail from '../BiAmountDetail';
import BiSummary from '../BiSummary';
import { prefixCls } from './index.scss';

const renderMap = [false, true]; // 将 renderType 转为布尔值

const renderReceiptPayment = page => {
  const { batchTitleInfo, contractList = [], extraPara, isEditOrShow } = page.props;
  const { renderType = extraPara?.renderType, canSwitchRenderType = extraPara?.canSwitchRenderType ?? true } =
    page.props;
  const { feeInfo } = page.state;
  let { renderType: realRenderType } = page.state;
  const cachedRenderType = window.localStorage?.getItem?.('picker_render_type') ?? '1';

  realRenderType =
    realRenderType ??
    (renderType || renderType === 0
      ? renderMap[renderType] ?? renderMap[cachedRenderType]
      : renderMap[cachedRenderType]); // 将 renderType 转为布尔值

  let BiPickOrderObj;
  if (page.state.tableList) {
    BiPickOrderObj = page.renderBiPickOrder();
  }
  const handleSwitchPage = val => {
    const str = val ? '切换到新版' : '返回到旧版';
    dataAnalyticTrack(['司机报销单', '新旧版切换', str]);
    page.setState({
      renderType: val,
    });
    window.localStorage?.setItem?.('picker_render_type', val ? 1 : 0);
  };

  const renderPicker = () => {
    return (
      <Load
        spinning={page.state.isLoading}
        contentRef={r => (page.contentRef = r)}
        style={{
          height: `calc(100% - 0px)`,
          ...(!realRenderType ? { position: 'absolute', left: '110%' } : { position: 'relative', left: 0 }),
        }}
      >
        {canSwitchRenderType ? (
          <div
            className={classnames({
              [`${prefixCls}__control`]: true,
            })}
          >
            <div
              className={classnames({
                [`${prefixCls}__control--switch-btn`]: true,
                [`is-new`]: realRenderType,
              })}
              onClick={() => handleSwitchPage(!realRenderType)}
            >
              <IconSwap />
              <span style={{ marginLeft: 4 }}>{realRenderType ? '返回到旧版' : '切换到新版'}</span>
            </div>
          </div>
        ) : null}
        <div
          className={classnames({
            [`${prefixCls}__header`]: true,
          })}
          ref={r => (page.cardFormHeader = r)}
        >
          {batchTitleInfo && Object.keys(batchTitleInfo).length !== 0 && page.renderTitle()}
        </div>
        <CardBox title="报销数据" className={classnames(`${prefixCls}__card-box`)}>
          <div
            className={classnames({
              [`${prefixCls}__table_wrap`]: true,
              [`${prefixCls}__table_wrap_unedit`]: page.state.type === 'show',
            })}
          >
            {BiPickOrderObj}
          </div>
        </CardBox>
        <CardBox title="费用项明细" className={classnames(`${prefixCls}__card-box`)}>
          <BiAmountDetail
            ref={r => (page.amountDetailListRef = r)}
            handleCheckAmountDetailExpense={page.handleCheckAmountDetailExpense}
            onChangeAll={page.handleAmountDetailChangeAll}
            action={page.state.type}
          />
        </CardBox>

        {feeInfo ? (
          <CardBox
            title="公司付款信息"
            className={classnames(`${prefixCls}__card-box`)}
            header={
              <div className={`${prefixCls}__card-box--head`}>
                <Icon
                  iconType="icon-help"
                  tipsCls="icon-help"
                  placement="top"
                  tips="此处填写给报销单收款人付款所需要使用的公司账户信息，当报销单结算后，此处展示的内容为结算所使用的账户信息，使用账本结算，账本余额、抵扣后余额展示的也为结算时的数据。"
                />
              </div>
            }
          >
            {page.props.extraForm && page.props.extraForm()}
            {contractList.length > 0 && (
              <div className={`${prefixCls}__head`}>
                <div className="title">收支方式</div>
                {page.renderContract()}
              </div>
            )}
            <BiSummary
              ref={r => (page.biFeeSummaryRef = r)}
              simple
              style={{ marginRight: 'auto', width: '100%' }}
              summary={page.getSummaryForShow()}
            />
            {page.renderFeeInfo()}
          </CardBox>
        ) : null}

        <Affix offsetBottom={0} target={() => page.contentRef} targetContainer={() => page.pickOrderWrap}>
          {/* 复用BiPickOrder中的按钮点击逻辑 */}
          <BiSummary
            ref={r => (page.amountDetailSummaryRef = r)}
            rightButtons={page.getCustomButtons()}
            tab={() => page.biPickOrder?.getTab?.()}
            category={() => page.biPickOrder?.getCategory?.()}
            btnClickCallback={page.handleCustomBtnClick}
            stack
          />
        </Affix>
      </Load>
    );
  };

  const renderPayment = () => (
    <PaymentRequest
      res={page.props?.extraPara?.paymentInfo ?? {}}
      wrapClassname={'picker-receipt-payment-wrap'}
      style={{
        height: `calc(100% - 0px)`,
        ...(!realRenderType ? { position: 'relative', left: 0 } : { position: 'absolute', left: '110%' }),
      }}
      showBySlideDrager={false}
      isReceiptPayment
      opType="发起报销申请"
      onSaveSuccess={() => page.props?.handleFn?.('closeCallback')}
      customeHead={
        canSwitchRenderType ? (
          <div
            className={classnames({
              [`${prefixCls}__control`]: true,
            })}
          >
            <div
              className={classnames({
                [`${prefixCls}__control--switch-btn`]: true,
                [`is-new`]: realRenderType,
              })}
              onClick={() => handleSwitchPage(!realRenderType)}
            >
              <IconSwap />
              <span style={{ marginLeft: 4 }}>{realRenderType ? '返回到旧版' : '切换到新版'}</span>
            </div>
          </div>
        ) : null
      }
    />
  );
  // {page.getTruckTeamSource()}
  return (
    <div
      className={classnames({
        [`${prefixCls}__wrap`]: true,
        [`is-flow`]: page.props.isFiddle || page.props.isBillAdjust || page.state.showInvoiceInfo,
        [`is-detail`]: page.props.type !== 'add',
        [`is-receipt-payment`]: true,
      })}
      ref={r => (page.pickOrderWrap = r)}
    >
      {renderPicker()}
      {!isEditOrShow ? renderPayment() : ''}
    </div>
  );
};

export default renderReceiptPayment;
