import { ColorTips, Flex, MenuBtn, Page } from 'components';
import { BUTTON_ICON, DYNAMIC_BUTTON_URL } from 'constants';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { buttonOpDialog, deleteProps, EventListener, fetchApi, isEmptyObj, typeIs } from 'utils';
import { dataAnalyticTrack } from 'utils/dataAnalytic';
import {
  configBtnProp,
  notFlatBtnKeys,
  /* 集合到打印下的key */ PRINTER_CONFIG,
  /* 打印预览之类的key */ printKeys,
  printPreviewKeys,
  printTplKeys,
  staticBtnKeys,
} from './constants';
import { prefixCls } from './index.scss';

export default class OrderButtons extends PureComponent {
  static propTypes = {
    category: PropTypes.string,
    tab: PropTypes.string,
    data: PropTypes.object,
    handleClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    total: PropTypes.object,
    handleNextPage: PropTypes.func,
    handlePrevPage: PropTypes.func,
    isShowPage: PropTypes.bool,
    isShowButton: PropTypes.bool,
    shouldPageShow: PropTypes.bool, // 数据变更时分页是否显示
    colorRule: PropTypes.array, // 颜色设置规则
    pages: PropTypes.array,
    defaultRightList: PropTypes.array, // 配置的强制在右侧显示的buttonKey名
    buttonAreaInfo: PropTypes.any, // button 和 page 之间 的 自定义内容
    flat: PropTypes.bool, // 扁平化，效果是 当有子菜单且子菜单只有一项时直接展示子菜单
    hasStaticBtn: PropTypes.bool,
    page: PropTypes.object,
  };
  static defaultProps = {
    flat: true,
    hasStaticBtn: true,
    isShowButton: true,
  };

  constructor(prop) {
    super(prop);
    this.state = {
      isShowMoreBtn: false,
      firstHideBtn: -1,
      ...this.getButtons(prop.data),
    };
  }

  componentDidMount() {
    this.listen = EventListener.listen(window, 'resize', () => {
      this.handleIsShowMoreBtn();
    });
    this.handleIsShowMoreBtn();
  }

  componentDidUpdate() {
    setTimeout(this.handleIsShowMoreBtn);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    this.setState({ ...this.getButtons(data) });
  }

  componentWillUnmount() {
    this.listen.remove();
  }

  toggleMoreBtn = show => {
    if (this.btnContainerMoreHeight >= 50 && this.moreBtn) {
      show ? this.moreBtn.handleShow() : this.moreBtn.handleHide();
      if (this.moreBtn.getContentNode()) {
        this.moreBtn.getContentNode().style.height = 'auto';
      }
    }
  };
  handleIsShowMoreBtn = () => {
    if (this.vBtnContainer) {
      this.btnContainerMoreHeight = this.vBtnContainer.offsetHeight;
      const buttons = this.vBtnContainer.childNodes;
      let firstHideBtn = -1;
      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].offsetTop > 5) {
          firstHideBtn = i - 1;
          break;
        }
      }
      // 当开启hasStaticBtn后，因为依赖state.isShowMoreBtn 所以容易造成死循环。所以加上firstHideBtn !== this.state.firstHideBtn条件
      if (!this.props.hasStaticBtn || firstHideBtn !== this.state.firstHideBtn) {
        if (this.btnContainerMoreHeight <= 50 && this.state.isShowMoreBtn) {
          this.setState({ isShowMoreBtn: false });
        } else if (this.btnContainerMoreHeight > 50 && !this.state.isShowMoreBtn) {
          this.setState({ isShowMoreBtn: true });
        }
      }
      if (firstHideBtn !== this.state.firstHideBtn) {
        this.setState({ firstHideBtn });
      }
    }
  };
  // 异步处理的button。  其中包括：切换模板
  dynamicBtn = (btnsProps, key, buttonAllData, selected, e) => {
    let btnRef = this[`${key}_btn`];
    if (e) {
      btnRef = this.moreBtn;
    }
    if (
      btnsProps &&
      !btnsProps.resetbutton &&
      btnsProps.dynamic &&
      btnsProps.dynamic !== false &&
      btnsProps.dynamic !== 'false'
    ) {
      const req = {
        dynamic: buttonAllData.dynamic,
        category: this.props.category,
        tab: this.props.tab || _.get(this.props, 'page.props.data.res.btn_tab'),
        button: key,
        sublist: buttonAllData.sublist.map(x => x.key),
        batch_type: _.get(this.props, 'page.props.data.res.batch_type'),
      };
      fetchApi(DYNAMIC_BUTTON_URL, { method: 'POST', body: { req } }).then(res => {
        if (res.errno !== 0 && !buttonAllData.sublist.length) {
          buttonOpDialog(res.res || {});
          return false;
        }
        const data = { ...(this.state.data || {}) };
        data[key].resetbutton = true;
        if (res.errno === 0) {
          const resData = res.res || {};
          const dynamicSublist = resData[key] || {};
          const childList = dynamicSublist.children;
          if (childList && typeIs(childList, 'object')) {
            Object.keys(dynamicSublist.children || {}).forEach(sublistKey => {
              // 以返回的内容中的key为准
              const dynamicSublistItem = dynamicSublist.children[sublistKey];
              data[key].sublist.push({
                key: sublistKey,
                ...dynamicSublistItem,
                itemIconType:
                  dynamicSublistItem.children && dynamicSublistItem.children.length ? 'icon-arrow-r-i' : undefined,
              });
            });
          } else {
            (childList || []).forEach(sublistKey => data[key].sublist.push(sublistKey));
          }
          data[key].resetbutton = true;
          if (e) {
            this.setState({ data });
          }
          const newSublist = data[key].sublist.map(item => {
            const buttonIcon = BUTTON_ICON[item.key] || BUTTON_ICON[item.title] || BUTTON_ICON[key] || {};
            return { ...item, ...buttonIcon };
          });
          btnRef && btnRef.resetSublist(newSublist, e);
          return false;
        }
        if (buttonAllData.sublist.length) {
          btnRef && btnRef.resetSublist(buttonAllData.sublist, e);
        }
      });
      return false;
    }
    if (
      btnsProps &&
      btnsProps.dynamic &&
      btnsProps.dynamic !== false &&
      btnsProps.dynamic !== 'false' &&
      !(selected && selected.isSublistClick)
    ) {
      btnRef &&
        btnRef.resetSublist(
          buttonAllData.sublist.map(item => {
            const buttonIcon = BUTTON_ICON[item.key] || BUTTON_ICON[item.title] || BUTTON_ICON[key] || {};
            return { ...item, ...buttonIcon };
          }),
          e,
        );
    }
    return true;
  };
  dataTrackPrint = key => {
    const printKey = ['print', 'printer'];

    if (!printKey.includes(key)) {
      return;
    }

    const pathName = window.location.pathname;
    const tabName = this.props.tab || _.get(this.props, 'page.props.data.res.btn_tab');

    if (pathName === '/Order/orderList') {
      dataAnalyticTrack(['订单打印', '订单列表-打印']);
    }
    if (pathName === '/Order/coInfo') {
      dataAnalyticTrack(['订单打印', '订单详情-打印']);
    }
    if (pathName === '/Finance/orderCheck') {
      dataAnalyticTrack(['订单打印', '订单审核列表-打印']);
    }
    if (tabName === 'receipt_payment_v1') {
      dataAnalyticTrack(['订单打印', '订单对账-客户详情-打印']);
    }
    if (tabName === 'pickup_load_detail_v1') {
      dataAnalyticTrack(['订单打印', '运单装车清单-打印']);
    }
  };
  // sublist 是selected 的父级
  onClick = (key, sublist, buttonData, buttonAllData) => selected => {
    const btnsProps = this.state.data[key];
    const res = this.dynamicBtn(btnsProps, key, buttonAllData, selected);

    // 点击下拉打印选项时
    if (selected) {
      this.dataTrackPrint(key);
    }

    if (!res) return;
    // 动态按钮，请求下拉
    this.props.handleClick && this.props.handleClick(selected, key, sublist, buttonData, buttonAllData);
  };
  onHideClick = (selected = {}, e) => {
    const { key } = selected;
    const btnsProps = this.state.data[key];
    const res = this.dynamicBtn(btnsProps, key, selected, undefined, { target: e.target });
    if (!res) return;
    this.toggleMoreBtn(false);
    // 更多下拉的第一级按钮， selected.sublist 为空
    this.props.handleClick && this.props.handleClick(selected, selected.key, selected.sublist, selected.date, selected);
  };

  moreBtnRefGetter = r => (this.moreBtn = r);
  setPage = page => {
    this.page && this.page.setPage(page);
  };
  //  button 属性
  getMenuBtnProps = moreBtnSublist => {
    const menuBtnProps = {
      direction: 'left-down',
      buttonProps: {
        children: '更多',
        direction: 'right',
        iconType: 'icon-list-down',
        clickLimit: false,
        onMouseEnter: () => this.toggleMoreBtn(true),
        onMouseLeave: e => {
          if (!this.moreBtn) return;
          const menu = document.querySelector('.fn-waybill-buttons_morebtn_droplist');
          const mouseInMenu = e.relatedTarget !== window && menu && menu.contains(e.relatedTarget);
          !mouseInMenu && this.toggleMoreBtn(false);
        },
      },
      propsChangeAutoHide: false,
      classname: `${prefixCls}_morebtn`,
      onClick: this.onHideClick,
      subList: moreBtnSublist,
      display: true,
      uniqueKey: 'key',
      tableHeader: { title: '' },
      style: { width: 180, maxWidth: 180 },
      sublistStyle: { width: 165, minWidth: 160 },
      menuStyle: { order: 100 },
    };
    return { ...menuBtnProps };
  };
  getBtn = (k, v, sublistIcon = true) => {
    // 需要浅拷贝一层，否则会改变原对象。下次调用本方法时会有问题
    const value = { ...v };
    let key = k;
    // 当只有一项时替换按钮为sublist[0](可能有些打印按钮未统计到，所以有tpl_key的也不flat)
    if (
      this.props.flat &&
      value.sublist &&
      value.sublist.length === 1 &&
      !notFlatBtnKeys[key] &&
      !value.sublist[0].tpl_key
    ) {
      value.key = value.sublist[0].key;
      key = value.sublist[0].key;
      value.sublist = [];
    }
    const { children } = value;
    const menuBtnProps = deleteProps(value, 'children', 'sublist', 'type', 'display', 'disabled', 'loading');
    const PackSwitchBtn =
      this.props.tab && this.props.tab.indexOf('_left') === -1 && key === 'pack_order'
        ? 'packOrderFR PackSwitchBtn'
        : 'PackSwitchBtn';
    const btnProps = {
      ...value,
      // propsChangeAutoHide: !value.resetbutton,
      display: value.display,
      buttonProps: {
        iconType: menuBtnProps.iconType,
        ...(BUTTON_ICON[key] || BUTTON_ICON[value.children]), // iconType: icon.iconType,
        children,
        type: key === 'scan_receipt' ? 'positive-r' : value.btnType,
        disabled: value.disabled,
        loading: value.loading,
        ...(key === 'config' || key === 'set_header' || key === 'pick_refresh'
          ? { tips: value.children, ...configBtnProp }
          : {}), // 需要覆盖前值
        ...(key === 'pack_order'
          ? { direction: 'both', iconRightType: 'icon-arrow-down', iconRightClass: 'packIconRight' }
          : {}),
      },
      classname: `${prefixCls}_btn btn-${key} waybill-buttons-${value.children}${
        key === 'config' ? 'btn-setting' : ''
      } ${key === 'pack_order' ? PackSwitchBtn : ''}`,
      onClick: this.onClick(key, value.sublist, value.data, value),
      subList:
        value.sublist && value.sublist.length && sublistIcon
          ? value.sublist.map(item => ({
              ...item,
              isHidebtn: true,
              ...(BUTTON_ICON[item.key] || BUTTON_ICON[item.title]),
            }))
          : value.sublist &&
            value.sublist.length &&
            value.sublist.map(item => ({ ...item, ...(BUTTON_ICON[item.key] || BUTTON_ICON[item.title]) })),
      tableHeader: { title: '' },
      menuStyle: { marginRight: '6px', order: staticBtnKeys[key] || 0 },
      // 子节点过宽会有遮挡
      style: { width: 165, minWidth: 160 },
      resetbutton: false,
      key,
    };
    return <MenuBtn direction="left" ref={r => (this[`${key}_btn`] = r)} {...menuBtnProps} {...btnProps} />;
  };
  // function getCustomUtilButton(data) {
  //   if (!data || !data.length) {
  //     return null
  //   }
  //   const Btns = []
  //   Object.entries(data).forEach(([key, value]) => {
  //     if (value) {
  //       Btns.push(getBtn(key, value))
  //     }
  //   })
  //   return Btns
  // }
  // function getClearButton(data) {
  //   if (!data.clear_pick_clip) {
  //     return null
  //   }
  //   return getBtn('clear_pick_clip', data.clear_pick_clip)
  // }
  getButtons = theData => {
    const data = { ...theData };
    const printer = { ...(theData.printer || { ...PRINTER_CONFIG, sublist: [] }) };

    if (!data) return;
    // 保证printer在最后一个
    // delete data.printer
    // 等同于printer的按钮
    Object.keys(printTplKeys).forEach(key => {
      if (data[key]) {
        data.printer = { ...data[key], children: '打印' };
        delete data[key];
      }
    });
    // if (data.bill_detail_print) {
    //   data.printer = { ...data.bill_detail_print }
    //   delete data.bill_detail_print
    // }
    // 打印预览挪到打印里面 并改名叫【列表】
    // 单据中心的打印特殊处理 改名叫【打印单据】挪到 子菜单
    if (printKeys.some(k => data[k])) {
      data.printer = { ...(data.printer || printer) };
      // if (!data.printer) {
      //   data.printer = PRINTER_CONFIG
      // }
      // 设置打印button的dynamic，且取第一个button的dynamic配置
      // if (data.printer && Object.keys(printTplKeys).length && data[printTplKeys[0]]) {
      //   data.printer.dynamic = data[printTplKeys[0]].dynamic
      // }
      // 打印列表之类的 集成到打印 第一项
      Object.keys(printPreviewKeys).forEach(k => {
        if (data[k] && !_.find(data.printer.sublist, { key: k })) {
          data.printer.sublist.unshift({
            key: k,
            children: false,
            title: printPreviewKeys[k],
          });
        }
        delete data[k];
      });
      // 打印模板之类的 集成到打印的子菜单里
      // Object.keys(printTplKeys).forEach(k => {
      //   if (data[k] && data[k].sublist) {
      //     data[k].dynamic && (data.printer.dynamic = data[k].dynamic)
      //     data[k].sublist.forEach(tpl => {
      //       if (!_.find(data.printer.sublist, tpl)) {
      //         data.printer.sublist.push(tpl)
      //       }
      //     })
      //     delete data[k]
      //   }
      // })
    }
    if (data.printer) {
      // 统一打印sublist icon
      data.printer.sublist = [...data.printer.sublist];
      data.printer.sublist.forEach(iitem => {
        const item = iitem;
        if (!item.iconType) {
          item.iconType = BUTTON_ICON['打印'].iconType;
        }
      });
    }
    return { data };
  };
  resetButttons = needConfig => {
    const isShowMoreBtn = (this.state || {}).isShowMoreBtn || false;
    const leftBtns = [];
    const rightBtns = [];
    const leftBtnConfig = [];
    const rightBtnConfig = [];
    const staticBtns = [];
    const staticBtnConfig = [];
    Object.entries(this.state.data).forEach(([key, value]) => {
      if (value) {
        // 当不显示更多时不显示clear btn
        const configItem = {
          ...value,
          [value.showKey]: value.children,
          [value.uniqueKey]: key,
          ...BUTTON_ICON[value.children],
          itemIconType: value.sublist && value.sublist.length ? 'icon-arrow-r-i' : undefined,
          children: value.sublist
            ? value.sublist.map(item => ({
                ...item,
                isHidebtn: true,
                ...(BUTTON_ICON[item.title] || BUTTON_ICON[item.key]),
              }))
            : [],
        };
        if (staticBtnKeys[key] && this.props.hasStaticBtn) {
          staticBtns.push(this.getBtn(key, value));
          needConfig && staticBtnConfig.push(configItem);
          return;
        }
        if (
          value.direction === 'right' ||
          (this.props.defaultRightList && this.props.defaultRightList.indexOf(key) !== -1)
        ) {
          rightBtns.push(this.getBtn(key, value, false));
          needConfig && rightBtnConfig.push(configItem);
        } else {
          leftBtns.push(this.getBtn(key, value));
          needConfig && leftBtnConfig.push(configItem);
        }
      }
    });
    // config 放到最后
    rightBtns.sort(item => (item.key === 'config' ? 1 : 0));
    return { leftBtns, rightBtns, leftBtnConfig, rightBtnConfig, staticBtns, staticBtnConfig };
  };
  // 页码信息
  renderPage = () => {
    const { handlePrevPage, handleNextPage, total, pages } = this.props;
    return (
      <Page
        ref={r => (this.page = r)}
        prevPage={handlePrevPage}
        nextPage={handleNextPage}
        pageSize={total.pageSize}
        pageTotal={+total.pageTotal}
        pageNum={total.pageNum}
        pages={pages}
      />
    );
  };
  render() {
    const { isShowPage, isShowButton, colorRule, shouldPageShow, buttonAreaInfo, className } = this.props;
    const { isShowMoreBtn, firstHideBtn } = this.state;
    const { leftBtns, rightBtns, leftBtnConfig, staticBtns } = this.resetButttons(true);
    const staticBtnWidth = staticBtns.length * 76 + 30;
    const marginRight = staticBtns.length ? staticBtnWidth : 0;

    const moreBtnSublist = firstHideBtn >= 0 ? leftBtnConfig.slice(firstHideBtn) : [];
    if (moreBtnSublist.length > 0) {
      const menuBtnProps = this.getMenuBtnProps(moreBtnSublist);
      leftBtns.splice(
        firstHideBtn,
        0,
        <MenuBtn ref={this.moreBtnRefGetter} direction="left-down" iconRightType="icon-list-down" {...menuBtnProps} />,
      );
    }
    return leftBtns.length > 0 || rightBtns.length > 0 || staticBtns.length || isShowPage ? (
      <div className={`${prefixCls} ${className} clearfix`}>
        <div className={`${prefixCls}__inner`}>
          {isShowButton && (
            <div className={`${prefixCls}__left`}>
              <div className={`${prefixCls}__group`} style={{ height: 32, marginRight }}>
                <div ref={r => (this.vBtnContainer = r)}>{leftBtns.map(item => item)}</div>
              </div>
              {!!staticBtns.length && <Flex className="more">{staticBtns.map(item => item)}</Flex>}
            </div>
          )}
          {buttonAreaInfo}
          {(isShowPage || rightBtns.length > 0 || colorRule) && (
            <div className={`${prefixCls}__right ${rightBtns.length < 2 && !isShowPage ? 'no-btn' : ''}`}>
              {!isEmptyObj(colorRule) && <ColorTips colorRule={colorRule} />}
              {isShowPage && shouldPageShow && this.renderPage()}
              {rightBtns.map(item => item)}
            </div>
          )}
        </div>
      </div>
    ) : null;
  }
}
