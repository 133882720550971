import { useDebounceFn } from '@gui/hooks-react';
import { IconUniDirectionRightOutline, IconUniTipsClockcircleOutline } from '@gui/icon-react';
import { Avatar, List, Progress } from '@gui/web-react';
import React, { useEffect, useRef, useState } from 'react';
import browserHistory from 'routes/history';
import { directToTaskDetail } from 'utils/business/task/direct';
import { dataAnalyticTrack } from 'utils/dataAnalytic';
import { viewImage } from 'utils/imageService';
import { v4 as uuidv4 } from 'uuid';
import MessageCard from '../MessageCard';
import { useMessages, useMessagesDispatch } from '../messagesContext';
import { prefixCls } from './index.scss';

export default function MessageList() {
  const { messages, isDisableSend, maxChatRecordId, send, scrollOption } = useMessages();
  const { isToBottom, immediate, lastHistoryLength, isHistoryMode } = scrollOption ?? {};
  const dispatch = useMessagesDispatch();
  const [loading, setLoading] = useState(false);
  const footerRef = useRef(null);
  const headerRef = useRef(null);
  const listRef = useRef(null);
  const { run } = useDebounceFn(el => onScroll(el), {
    wait: 500,
  });

  const handelScroll = () => {
    if (isHistoryMode) {
      listRef.current?.scrollIntoView(lastHistoryLength - 1, { behavior: 'smooth' });
      dispatch({
        type: 'SCROLL_TO',
        isHistoryMode: false,
      });
      setTimeout(() => setLoading(false), 1000);
    } else if (isToBottom) {
      setTimeout(() => footerRef?.current?.scrollIntoView?.({ behavior: 'smooth' }), 300);
    }
  };

  useEffect(() => {
    handelScroll();
  }, [messages]);

  useEffect(() => {
    if (immediate) {
      handelScroll();
      dispatch({
        type: 'SCROLL_TO',
        isToBottom: true,
        immediate: false,
      });
    }
  }, [immediate]);

  const getChatHistory = () => {
    if (lastHistoryLength < 1) {
      return;
    }

    setLoading(true);
    dispatch({
      type: 'SCROLL_TO',
      isHistoryMode: true,
    });

    const oldestRecordIndex = messages.findIndex(el => typeof el?.chatId === 'number' && el.chatId < maxChatRecordId);
    let params = {
      dataType: 'llm_chat_recovery',
      size: 10,
    };

    // 后续查历史记录
    if (oldestRecordIndex > -1) {
      params = {
        ...params,
        max_chat_record_id: messages[oldestRecordIndex].chatId,
      };
    } else {
      // 第一次查接口返回的 max_chat_record_id
      params = {
        ...params,
        max_chat_record_id: maxChatRecordId,
      };
    }

    send(params);

    dataAnalyticTrack(['大模型智能助手', '智能录单助手', '查看历史记录']);
  };

  const getListHeader = () => {
    return (
      <div onClick={() => getChatHistory()} ref={headerRef} className={`${prefixCls}-header`}>
        <IconUniTipsClockcircleOutline className={`${prefixCls}-header__icon`} />
        {lastHistoryLength > 0 ? '查看更多记录' : '没有更多记录了'}
      </div>
    );
  };

  const renderCardMessage = item => {
    const {
      role = '',
      chatId,
      textList = [],
      isFinished,
      isHistory = false,
      historyFeedback,
      cardList = [],
    } = item ?? {};

    return (
      <div className={`${prefixCls}-message ${role}`}>
        <Avatar className={`${prefixCls}-message__avatar ${role}`}>
          {role === 'AI' ? (
            <img
              alt="avatar"
              src="https://static.g7cdn.com/fe-cdn/cyt/cmtx-tms-pro-frontend-web/images/chat/avatar-ai.png"
            />
          ) : (
            '我'
          )}
        </Avatar>

        <div className={`${prefixCls}-message__card ${role}`}>
          <div className={`${prefixCls}-message__text mb-10`}>
            {textList.map((message, i) => (
              <div key={i}>{message}</div>
            ))}
          </div>
          {cardList.length > 0 && (
            <div className={`${prefixCls}-message__text`}>
              <MessageCard
                list={cardList}
                isHistory={isHistory}
                historyFeedback={historyFeedback}
                isFinished={isFinished}
                chatId={chatId}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderTextList = (textList, type) => {
    if (type !== 'AI_ADD_GUIDE') {
      return (
        <div className={`${prefixCls}-message__text`}>
          {textList.length > 0 && textList?.map?.((text, i) => <div key={i}>{text}</div>)}
        </div>
      );
    }

    const handleGetGuideImage = j => {
      dispatch({
        type: 'AI_GUIDE_IMAGE',
        imageIndex: j,
      });
    };

    return (
      <div className={`${prefixCls}-message__text`}>
        {textList.length > 0 &&
          textList.map((text, j) => {
            const { title, description } = text ?? {};

            if (title && description) {
              return (
                <div onClick={() => handleGetGuideImage(j)} key={j} className={`${prefixCls}-list-item`}>
                  <div className="m-c-li-title">{text.title}</div>
                  <div className="m-c-li-content">{text.description}</div>
                  <span className="m-c-li-corner">
                    <IconUniDirectionRightOutline />
                  </span>
                </div>
              );
            } else {
              return <div key={j}>{text.description}</div>;
            }
          })}
      </div>
    );
  };

  const renderTextMessage = item => {
    const { role = '', type, textList = [], streamText = '' } = item ?? {};

    return (
      <div className={`${prefixCls}-message ${role}`}>
        <Avatar className={`${prefixCls}-message__avatar ${role}`}>
          {role === 'AI' ? (
            <img
              alt="avatar"
              src="https://static.g7cdn.com/fe-cdn/cyt/cmtx-tms-pro-frontend-web/images/chat/avatar-ai.png"
            />
          ) : (
            '我'
          )}
        </Avatar>
        <div className={`${prefixCls}-message__card ${role}`}>
          <div>
            {renderTextList(textList, type)}
            {streamText.length > 0 && <div className={`${prefixCls}-message__text`}>{streamText}</div>}
          </div>
        </div>
      </div>
    );
  };

  const renderImageMessage = item => {
    const { role = '', imgUrl } = item ?? {};

    return (
      <div className={`${prefixCls}-message ${role}`}>
        <Avatar className={`${prefixCls}-message__avatar ${role}`}>
          {role === 'AI' ? (
            <img
              alt="avatar"
              src="https://static.g7cdn.com/fe-cdn/cyt/cmtx-tms-pro-frontend-web/images/chat/avatar-ai.png"
            />
          ) : (
            '我'
          )}
        </Avatar>
        <div className={`${prefixCls}-message__imgBox ${role}`}>
          <img src={imgUrl} onClick={() => viewImage({ src: imgUrl }, [])} />
        </div>
      </div>
    );
  };

  const renderProcessMessage = item => {
    const { role = '', total_count, handled_count } = item ?? {};
    const percent = (handled_count / total_count) * 100;

    return (
      <div className={`${prefixCls}-message ${role}`}>
        <Avatar className={`${prefixCls}-message__avatar hidden`}>
          <img
            alt="avatar"
            src="https://static.g7cdn.com/fe-cdn/cyt/cmtx-tms-pro-frontend-web/images/chat/avatar-ai.png"
          />{' '}
          :
        </Avatar>
        <div className={`${prefixCls}-message__progress`}>
          <div className="text">执行进度</div>
          <Progress
            size="large"
            className="bar"
            percent={percent}
            formatText={() => `${handled_count}/${total_count}`}
          />
        </div>
      </div>
    );
  };

  const renderDoneMessage = item => {
    const {
      role = '',
      waybill_group_no,
      waybill_count,
      success_count,
      success_waybill_info,
      fail_count,
      task_id,
      waybill_origin_chat_record_id,
      error_msg,
    } = item ?? {};

    const showEdit = () => {
      dispatch({
        type: 'AI_CARD_EDIT_OPEN',
        chatId: Number(waybill_origin_chat_record_id),
        cardIndex: Number(waybill_group_no) - 1,
        errMsg: error_msg,
      });
      dataAnalyticTrack(['大模型智能助手', '智能录单助手', '建单结果-全部失败超链']);
    };

    const toTaskDetail = task => {
      const { car_batch, batch_id } = task ?? {};
      const query = {
        from: 'chat',
        batchID: batch_id,
        carBatch: car_batch,
        comID: window.company_id,
      };

      directToTaskDetail(query);

      dataAnalyticTrack(['大模型智能助手', '智能录单助手', '建单结果-运单号超链']);
    };

    const toOrderCenterDetail = id => {
      browserHistory.pushWithQuery({
        pathname: '/app/smartWaybillDetail',
        query: {
          from: 'chat',
          channel: 4,
          id,
        },
      });

      dataAnalyticTrack(['大模型智能助手', '智能录单助手', '建单结果-部分失败超链']);
    };

    return (
      <div className={`${prefixCls}-message ${role}`}>
        <Avatar className={`${prefixCls}-message__avatar ${role}`}>
          {role === 'AI' ? (
            <img
              alt="avatar"
              src="https://static.g7cdn.com/fe-cdn/cyt/cmtx-tms-pro-frontend-web/images/chat/avatar-ai.png"
            />
          ) : (
            '我'
          )}
        </Avatar>
        <div className={`${prefixCls}-message__card ${role}`}>
          <div className={`${prefixCls}-message__text`}>
            <div>
              运单组{waybill_group_no}执行建单完成喽，共计{waybill_count}单运单。
            </div>
            {success_count > 0 && (
              <div>
                成功{success_count}单，运单号：
                {success_waybill_info?.length > 0 &&
                  success_waybill_info.map((el, i) => (
                    <div key={el.car_batch}>
                      <a onClick={() => toTaskDetail(el)} className={`${prefixCls}-message__text-link`}>
                        {el.car_batch}
                      </a>
                      <span>{i === success_waybill_info.length - 1 ? '。' : '、'}</span>
                    </div>
                  ))}
              </div>
            )}
            {fail_count > 0 && fail_count !== waybill_count && (
              <div>
                失败需修改{fail_count}单，：
                <a onClick={() => toOrderCenterDetail(task_id)} className={`${prefixCls}-message__text-link`}>
                  点击这里查看失败原因明细。
                </a>
              </div>
            )}
            {fail_count === waybill_count && (
              <div>
                建单全部失败了，错误原因是{error_msg}
                <a onClick={() => showEdit()} className={`${prefixCls}-message__text-link`}>
                  点击这里修改运单信息后重新提交。
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const getMessage = item => {
    const { messageType } = item ?? {};

    const messageItem = {
      text: () => renderTextMessage(item),
      image: () => renderImageMessage(item),
      card: () => renderCardMessage(item),
      progress: () => renderProcessMessage(item),
      done: () => renderDoneMessage(item),
    };

    return messageItem?.[messageType]?.();
  };

  const checkInBottomArea = el => {
    // 距离底部阙值
    const offsetBottom = 100;

    return el.scrollTop + offsetBottom >= el.scrollHeight - el.offsetHeight;
  };

  const onScroll = el => {
    // 查看历史模式，或者超过底部阙值时滚动时，接收新消息将不滚到到底部
    if (isHistoryMode || !checkInBottomArea(el)) {
      dispatch({
        type: 'SCROLL_TO',
        isToBottom: false,
      });
    } else {
      dispatch({
        type: 'SCROLL_TO',
        isToBottom: true,
      });
    }
  };

  return (
    <List
      loading={loading}
      className={prefixCls}
      listRef={listRef}
      dataSource={messages}
      header={getListHeader()}
      onListScroll={el => run(el)}
      render={item => (
        <List.Item key={item.chatId || uuidv4()} style={{ padding: '0 16px 16px' }}>
          {getMessage(item)}
        </List.Item>
      )}
      noDataElement={null}
      footer={<div className={`${prefixCls}-footer`} ref={footerRef} />}
    />
  );
}
