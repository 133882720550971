import { POPUP_CONFIRM_WINDOW } from '@/utils/i18n/constants.js';
import { i18n } from '@/utils/i18n/index.js';
import { PopUp, ShowInfo, ShowList } from 'components';
import { colRender } from 'components/utilcomponents/Select';
import { ERROR, INFO, UN_CLOSE_ICON, WARN } from 'constants';
import _ from 'lodash';
import { getPrintLocalStorage } from 'operation/listOperation/printStorage';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import browserHistory from 'routes/history';
import { createTip, fetchApi, fromJS, opTips, setCookie, showInfo, throttle, typeIs } from 'utils';
import { dealCardPriceSnapshot } from 'utils/business/batchPickOrder/dealPriceSnapshot';
import { dataAnalyticTrack } from 'utils/dataAnalytic';
import { dispatchCarrierHeader } from 'utils/sug/dispatchCarrier';

const fromPageUrl = {};
// 不需要返回打开的列表页配置项。根据tab名称模糊匹配
const NOT_BACK_OPEN_LIST = ['新增配载', '收款', '付款', '员工交账', '对账'];
const greenTextStyle = { color: '#21AA6B' };
const carrierColRender = (item, ...rest) =>
  +item.trans_type === 1 ? <span style={greenTextStyle}>{colRender(item, ...rest)}</span> : colRender(item, ...rest);

export default class BasicPickOrder extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.sendReqSet = {
      clearIds: false,
      needBatchId: true,
    };
    this.idsKey = 'loading_od_link_ids';
    this.isCheckRightNum = false;
    this.failed_detail = false;
    // 左右跳单页完成后返回上一个url
    const lastUrl = window.globalHistoryRecord[window.globalHistoryRecord.length - 2];
    const tabName = _.get(props, 'config.query.tabName', _.get(props, 'config.tabName'));
    const shouldNotBackLastUrl = tabName && NOT_BACK_OPEN_LIST.find(item => tabName.indexOf(item) > -1);
    if (props.pageKey && !props.isRefresh) {
      fromPageUrl[props.pageKey] = lastUrl;
    }
    this.uniqueKey = 'order_num';
    this.fromPageUrl = shouldNotBackLastUrl ? this.redirectUrl : props.pageKey && fromPageUrl[props.pageKey];
  }
  static defaultProps = {};
  static propTypes = {
    // 当前页面状态
    type: PropTypes.string,
    // 页面render参数
    pickOrderInfo: PropTypes.any,
    // 从上一个页面中带入的运单id
    ids: PropTypes.any,
    // 从上一个页面中带入的运单号
    orderNum: PropTypes.array,
    // 关闭tab的func
    closeTab: PropTypes.func,
    // 刷新tab
    refreshTab: PropTypes.func,
    // 跳转tab的func
    redirectRouter: PropTypes.func,
    // 是否发送请求
    sendReq: PropTypes.bool,
  };
  componentDidMount = () => {
    this.props.sendReq && this.sendReq(this.props);
    this.changeStatus(this.props);
    if (!this.props.sendReq && !this.failed_detail) {
      this.handleFailedDetail(this.props.pickOrderInfo.toJS());
    }
  };

  handleDefaultPrint = pickOrderInfo => {
    const pickInfo = pickOrderInfo;
    if (this.printOrderKey && !this.handleDetaultPrintOrder && pickOrderInfo && pickOrderInfo.batchTableInfo) {
      this.handleDetaultPrintOrder = true;
      const sublist = _.get(pickInfo, 'batchTableInfo.right.otherForm.printOrder.otherProps.data');
      let printLocalStorage = getPrintLocalStorage(this.printOrderKey, sublist);
      if (printLocalStorage && printLocalStorage[0]) {
        printLocalStorage = printLocalStorage[0];
        _.set(pickInfo, 'batchTableInfo.right.otherForm.printOrder.otherProps.value', printLocalStorage);
      }
    }
    return pickInfo;
  };
  handleFailedDetail = ({ failed_detail: detail }) => {
    if (detail && detail.length) {
      this.failed_detail = detail;
      const pageInfo = this.props.getPageInfo && this.props.getPageInfo();
      let opType = pageInfo && ((pageInfo.query && pageInfo.query.tabName) || pageInfo.menuName);
      opType = opType || '';
      opTips({
        opType,
        orderType: '数据',
        resData: {
          errno: 0,
          res: { failed_detail: detail },
        },
        ext: { specialTips: `以下数据无法操作${opType}！` },
      });
    }
  };
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (this.props.pickOrderInfo !== nextProps.pickOrderInfo) {
      throttle(this.showRefreshTips, 50)(() => this.changeStatus(nextProps));
      if (this.props.sendReq && !this.failed_detail) {
        this.handleFailedDetail(nextProps.pickOrderInfo.toJS());
      }
    }

    if (window.location.pathname === '/Assign/load') {
      const carBatch = nextProps.orderArrList?.map?.(el => el.car_batch) ?? [];
      dataAnalyticTrack(['运单指派', '指派', carBatch.join()]);
    }
  };
  showRefreshTips = async callback => {
    const i18nDict = await i18n(POPUP_CONFIRM_WINDOW);
    const orderDict = i18nDict.get('order', '运单');
    const content = (
      <div className="dialog_detail">
        <p className="notice">该页面已经打开，确定要刷新页面吗?</p>
      </div>
    );
    const contentNotice = (
      <div className="dialog_detail checkinfo-detail">
        <p className="notice_title">操作提示:</p>
        <div style={{ postion: 'absolute' }} className="detail-content">
          <p className="notice_list">
            <em>●</em>点击[确定]，将刷新页面，之前挑到右屏的{orderDict}将消失，右屏只会显示本次选中的{orderDict}。
          </p>
        </div>
        <div style={{ postion: 'absolute' }} className="detail-content">
          <p className="notice_list">
            <em>●</em>点击[取消]，不刷新页面，保留之前挑到右屏的{orderDict}，本次选中的{orderDict}不显示。
          </p>
        </div>
      </div>
    );
    showInfo(
      INFO,
      content,
      true,
      {
        confirm: () => {
          this.props.refreshTab();
          callback && callback();
        },
      },
      undefined,
      undefined,
      contentNotice,
    );
  };
  // 检查右屏返回的运单和请求的运单数是不是相同， 不相同弹出提示, 刷新时不应再出提示
  checkRightTableNum = async (tableList, rightQuery, uniqueKey, selectList, selectPackBatchList, isPack) => {
    const transportKeyArr = ['driver_ca_ba_apply', 'car_ca_ba_apply', 'carrier_ca_ba_apply'];
    const clientKeyArr = ['client_ca_ba_apply', 'project_ca_ba_apply', 'create_new_account', 'receiptApply'];
    const { buttonKey } = this.state;
    // 车辆对账 / 司机对账 / 承运商对账 /客户运单对账 项目运单对账 客户订单对账 创建对账单时错误弹框还需要暂时另外的错误信息
    const isTransport = transportKeyArr.indexOf(buttonKey); // 是下游对账
    const isClient = clientKeyArr.indexOf(buttonKey); // 是上游对账
    const showMoreErrorTip = isTransport > -1 || isClient > -1;

    const i18nDict = await i18n(POPUP_CONFIRM_WINDOW);
    const orderDict = i18nDict.get('order', '运单'); // 运单改为订单
    const batchDict = i18nDict.get('batch', '任务'); // 任务改为运单
    const batchDictp = i18nDict.get('batch', '批次'); // 批次改为运单

    const pickInfos = this.props.pickOrderInfo.toJS() || {};
    let curUniqueKey =
      pickInfos.batchTableInfo &&
      pickInfos.batchTableInfo.tableFilter &&
      pickInfos.batchTableInfo.tableFilter.tab &&
      pickInfos.batchTableInfo.tableFilter.tab.includes('batch')
        ? 'car_batch'
        : 'order_num';
    const batchCarMap = ['await_settle_pay_mgr'];
    const curTab = _.get(pickInfos, 'batchTableInfo.tableFilter.tab');
    if (batchCarMap.includes(curTab)) {
      curUniqueKey = 'car_batch';
    }
    this.uniqueKey = this.uniqueKey || curUniqueKey || 'order_num';
    if (this.failed_detail || (!selectList && this.uniqueKey !== 'order_num')) {
      return;
    }

    const { failed_detail } = tableList;
    if (failed_detail) {
      opTips({
        opType: '交账',
        orderType: '数据',
        resData: {
          errno: 0,
          res: { failed_detail },
        },
        ext: { specialTips: '以下数据无法操作：' },
      });
      return true;
    }
    const rightList = (tableList.right || {}).data || [];
    let list = this.props.orderNum || [];
    const sucOrderNum = [];
    rightList.forEach(item => sucOrderNum.push(item[this.uniqueKey]));
    list = list.filter(x => sucOrderNum.indexOf(x) === -1);
    const PackList =
      rightQuery && rightQuery.query && rightQuery.query.b_link_id && rightQuery.query.b_link_id.length > 0;
    if (isPack && PackList) {
      const PackIds = [];
      const notSelectPack = [];
      rightList.forEach(i => {
        PackIds.push(i.b_link_id);
      });
      selectList.forEach((i, index) => {
        if (!PackIds.includes(i)) {
          notSelectPack.push(selectPackBatchList[index]);
        }
      });
      list = notSelectPack;
    }
    // if (rightList.length !== ((rightQuery.query || {})[uniqueKey] || []).length) {
    /**
     * 单票送货费、单票提货费 在操作结算/收付款时，如果运单有过折单
     * 返回的rightQuery格式和其它的不一样，所以用selectList来判断
     */
    let rightQueryLen = 0;
    if (selectList) {
      // 线上发放，导出打款单的 selectList 是个object
      rightQueryLen = typeIs(selectList, 'object') ? Object.values(selectList).length : selectList.length;
    } else {
      // 有时候后端返回的是 object, 在此转成数组
      const rightQueryKey = Object.values((rightQuery.query || {})[uniqueKey] || []);
      rightQueryLen = rightQueryKey.length;
      if ((rightQueryLen === 1 && rightQueryKey[0] === -1) || rightQueryLen === undefined) {
        rightQueryLen = 0;
      }
    }

    console.log('可能的原因 =', this.isCheckRightNum, rightQueryLen, rightList.length);
    // 财务在列表不勾选单子进入挑单时，rightList.length 就是0，没有单子
    if (rightList.length !== rightQueryLen && !this.isCheckRightNum) {
      const isReservation = pickInfos.reqInfo && pickInfos.reqInfo.category === 'Reservation';
      const orderStr = isReservation ? '订单' : orderDict;
      const titleText = isPack ? '打包单' : '数据';
      const _category =
        pickInfos.batchTableInfo && pickInfos.batchTableInfo.tableFilter
          ? pickInfos.batchTableInfo.tableFilter.category
          : '';
      // eslint-disable-next-line no-nested-ternary
      const pText = isPack ? '打包单' : _category === 'Batch' ? batchDictp : isReservation ? '订单' : orderDict;
      const taskList = ['payment_batch_task', 'settle_batch_task', 'receipt_batch_task', 'project_batch_detail'];
      const batchNotice = [
        'team_batch_detail',
        'driver_batch_detail',
        'car_batch_detail',
        'dispatch_batch_detail',
        ...taskList,
      ];
      const bText =
        pickInfos.reqInfo && pickInfos.reqInfo.tab && taskList.includes(pickInfos.reqInfo.tab) ? batchDict : batchDictp;
      const content = (
        <div className="dialog_detail">
          <p className="notice">{`以下${titleText}无法${this.opTips || '操作'}:`}</p>
          <ShowList list={list} style={{ marginTop: '8px' }} />
        </div>
      );
      const textNode = (
        <div style={{ postion: 'absolute' }} className="detail-content">
          <p className="notice_list">
            <em>●</em>该{pText}存在待审核或者审核中的修改记录，不允许进行当前操作！
          </p>
        </div>
      );
      const textNode2 = showMoreErrorTip ? (
        <>
          {pText === '订单' ? (
            ''
          ) : (
            <div style={{ postion: 'absolute' }} className="detail-content">
              <p className="notice_list">
                <em>●</em>该{pText}
                {isTransport > -1 ? '运力' : '客户'}考核费待审核/审核中，不允许进行当前操作！
              </p>
            </div>
          )}
          <div style={{ postion: 'absolute' }} className="detail-content">
            <p className="notice_list">
              <em>●</em>该{pText}存在未审核/审核异常/审核错误的审核记录，不允许进行当前操作！
            </p>
          </div>
          {/* 客户订单对账（收款按钮）对账单不展示未到达 */}
          {buttonKey === 'receiptApply' ? (
            ''
          ) : (
            <div style={{ postion: 'absolute' }} className="detail-content">
              <p className="notice_list">
                <em>●</em>该{pText}未到达，不允许进行当前操作！
              </p>
            </div>
          )}
        </>
      ) : (
        ''
      );
      // 结算功能 开启了禁止创建结算单规则时展示
      const textNode3 =
        buttonKey === 'settle_new' ? (
          <>
            <div style={{ postion: 'absolute' }} className="detail-content">
              <p className="notice_list">
                <em>●</em>该{pText}存在客户/运力考核费待审核/审核中的记录，不可结算
              </p>
            </div>
            <div style={{ postion: 'absolute' }} className="detail-content">
              <p className="notice_list">
                <em>●</em>该{pText}存在审核异常/错误的记录，不可结算
              </p>
            </div>
          </>
        ) : (
          ''
        );
      const noticeList =
        pickInfos.reqInfo && pickInfos.reqInfo.tab && batchNotice.includes(pickInfos.reqInfo.tab) ? (
          <Fragment>
            <div style={{ postion: 'absolute' }} className="detail-content">
              <p className="notice_list">
                <em>●</em>未结算金额为0的数据
              </p>
            </div>
            <div style={{ postion: 'absolute' }} className="detail-content">
              <p className="notice_list">
                <em>●</em>该{bText}已进行过相同的操作，需刷新页面
              </p>
            </div>
            <div style={{ postion: 'absolute' }} className="detail-content">
              <p className="notice_list">
                <em>●</em>该{bText}已删除，不允许进行当前操作
              </p>
            </div>
            {textNode}
            {textNode2}
            {textNode3}
          </Fragment>
        ) : (
          <Fragment>
            <div style={{ postion: 'absolute' }} className="detail-content">
              <p className="notice_list">
                <em>●</em>该{pText}已不在当前被操作网点的库存中
              </p>
            </div>
            <div style={{ postion: 'absolute' }} className="detail-content">
              <p className="notice_list">
                <em>●</em>该{pText}当前状态不符合系统设置的要求
              </p>
            </div>
            <div style={{ postion: 'absolute' }} className="detail-content">
              <p className="notice_list">
                <em>●</em>该{pText}已进行过相同的操作，需刷新页面
              </p>
            </div>
            <div style={{ postion: 'absolute' }} className="detail-content">
              <p className="notice_list">
                <em>●</em>该{pText}已作废或已删除，不允许进行当前操作
              </p>
            </div>
            {textNode}
            {textNode2}
            {textNode3}
          </Fragment>
        );
      const contentNotice = (
        <div className="dialog_detail checkinfo-detail">
          <p className="notice_title">可能的原因:</p>
          {noticeList}
        </div>
      );
      this.isCheckRightNum = true;
      // 工资单特殊处理，不需要这个提示
      const category = _.get(this, 'props.config.query.category');
      const tab = _.get(this, 'props.config.query.tab');
      const hasTips = !(
        ['DriverWage', 'DriverWageDetail'].includes(category) && ['driver_wage_detail', 'driver_wage'].includes(tab)
      );
      if (hasTips) {
        showInfo(ERROR, content, true, null, false, '操作提醒', contentNotice);
      }
    }
  };

  customValidity = val => {
    if (val && val.black_set === '1') {
      // showInfo(WARN, '当前司机已被拉黑')
      return { type: 'warn', msg: '当前司机已被拉黑' };
    } else if (val && val.black_set === '2') {
      return '当前司机已被拉黑';
    }
    return '';
  };
  // 发送请求
  sendReq = props => {
    const pack = {
      [this.idsKey]: this.sendReqSet.clearIds ? [] : props.ids || [],
      type: props.type,
      company_id: props.comId,
      route_id: props.route_id,
    };
    if (this.sendReqSet.needBatchId) {
      const pickOrderInfo = this.state.pickOrderInfo || props.pickOrderInfo.toJS() || {};
      const batchInfo = pickOrderInfo.batch_info || {};
      pack.b_link_id = batchInfo.batch_id;
    }
    const param = { method: 'POST', body: { req: { ...pack, ...this.otherParams } } };
    fetchApi(this.pageUrl, param).then(res => {
      if (res.errno !== 0) {
        createTip(res.errmsg || '获取数据失败', WARN).show();
        return;
      }
      const pickOrderObj = this.context.getPick();
      const newPickOrderInfo = this.dealheader(res.res || {});
      pickOrderObj && pickOrderObj.resetPickInfoHeader(newPickOrderInfo);
      const dr_name = _.get(newPickOrderInfo, 'batchTitleInfo.cardInfo.dr_name');
      const dr_phone = _.get(newPickOrderInfo, 'batchTitleInfo.cardInfo.dr_phone');
      const dr_assistant_name = _.get(newPickOrderInfo, 'batchTitleInfo.cardInfo.dr_assistant_name');
      const dr_assistant_phone = _.get(newPickOrderInfo, 'batchTitleInfo.cardInfo.dr_assistant_phone');
      if (dr_name) {
        newPickOrderInfo.batchTitleInfo.cardInfo.dr_name.customValidity = this.customValidity;
      }
      if (dr_phone) {
        newPickOrderInfo.batchTitleInfo.cardInfo.dr_phone.customValidity = this.customValidity;
      }
      if (dr_assistant_name) {
        newPickOrderInfo.batchTitleInfo.cardInfo.dr_assistant_name.customValidity = this.customValidity;
      }
      if (dr_assistant_phone) {
        newPickOrderInfo.batchTitleInfo.cardInfo.dr_assistant_phone.customValidity = this.customValidity;
      }
      const pickOrderInfo = this.handleDefaultPrint(newPickOrderInfo);
      this.setState({ pickOrderInfo }, () => this.changeStatus({ ...props, pickOrderInfo: fromJS(pickOrderInfo) }));
    });
  };
  // 更新state状态
  changeStatus = props => {
    const pageType = props.type;
    const pickOrderInfo = props.pickOrderInfo.toJS() || {};
    const batchInfo = pickOrderInfo.batchInfo || pickOrderInfo.batch_info || {};
    const batchId = batchInfo.batch_id;
    const { comId } = props;
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      type: pageType,
      pickOrderInfo: this.dealheader(pickOrderInfo),
      // eslint-disable-next-line react/no-unused-state
      comId,
      // eslint-disable-next-line react/no-unused-state
      companyInfo: batchInfo.company_info || {},
      // eslint-disable-next-line react/no-unused-state
      batchId,
    });
  };
  // 统一添加保存icon操作
  dealheader = pickOrderInfo => {
    // 统一添加保存icon操作
    const { transType } = this.props;
    const titleInfo = pickOrderInfo.batchTitleInfo || {};
    if (titleInfo.cardFormHeader) {
      titleInfo.cardFormHeader.un_realod_page = JSON.parse(JSON.stringify(UN_CLOSE_ICON));
      this.reloadPage && (titleInfo.cardFormHeader.un_realod_page.otherProps.iconType = 'icon-nail-1');
      titleInfo.cardFormHeader.un_realod_page.otherProps.tipsProps = { tipsStyle: { width: '200px' } };
      if (this.checkBatchItem) {
        this.manualEnable === false && (titleInfo.cardFormHeader[this.batchKey].otherProps.disabled = true);
      }
    }
    if (titleInfo.cardInfo && titleInfo.cardInfo.sync_arr) {
      const value = localStorage[`${window.user_id}_synArr`] || true;
      titleInfo.cardInfo.sync_arr.otherProps.checked = value !== 'false';
    }
    if (transType === 1 && titleInfo.cardInfo && titleInfo.cardInfo.carrier_id) {
      const carrier = titleInfo.cardInfo.carrier_id;
      carrier.otherProps.tableHeader = [
        { key: 'company_name', title: '所属组织', render: carrierColRender },
        { key: 'carrier_name', title: '公司名称', render: carrierColRender },
        { key: 'phone2', title: '联系电话', render: carrierColRender },
        { key: 'trans_type_text', title: '承运商类型', render: carrierColRender },
      ];
    }
    if (titleInfo.cardInfo && titleInfo.cardInfo.b_tr_team_id) {
      titleInfo.cardInfo.b_tr_team_id.otherProps.tableHeader = dispatchCarrierHeader;
    }
    if (titleInfo.cardInfo && titleInfo.cardInfo.b_tr_team_name) {
      titleInfo.cardInfo.b_tr_team_name.otherProps.tableHeader = dispatchCarrierHeader;
    }
    if (titleInfo.cardInfo && titleInfo.cardInfo.one_way_of_miles) {
      titleInfo.cardInfo.one_way_of_miles.otherProps.children = <span className="input-icon unit">km</span>;
    }
    if (titleInfo.cardInfo && titleInfo.cardInfo.total_time) {
      titleInfo.cardInfo.total_time.otherProps.children = <span className="input-icon unit">h</span>;
    }
    if (titleInfo.cardInfo && titleInfo.cardInfo.line_time) {
      titleInfo.cardInfo.line_time.otherProps.children = <span className="input-icon unit">h</span>;
    }
    if (titleInfo.cardInfo && titleInfo.cardInfo.standard_oil) {
      titleInfo.cardInfo.standard_oil.otherProps.children = <span className="input-icon unit">升/百公里</span>;
    }
    if (titleInfo.cardInfo && titleInfo.cardInfo.theory_oil) {
      titleInfo.cardInfo.theory_oil.otherProps.children = <span className="input-icon unit">升</span>;
    }
    return this.handleDefaultPrint(dealCardPriceSnapshot(pickOrderInfo));
  };

  // 是否跳转页面的状态保存
  iconNailClick = prefix => {
    const biPick = this.context.getPick();
    const header = biPick.cardForm.getStateHeader();
    header.un_realod_page.otherProps.iconType = this.reloadPage ? 'icon-nail' : 'icon-nail-1';
    biPick.cardForm && biPick.cardForm.setHeaderData(header);
    const timeout = new Date();
    timeout.setDate(timeout.getDate() + 365);
    setCookie(`${prefix}_${window.user_id}`, !this.reloadPage, timeout, '/');
    this.reloadPage = !this.reloadPage;
  };
  // 投保资料未审核
  improveInfoDialog = content => {
    new PopUp(ShowInfo, {
      type: WARN,
      content, // : '您的投保资料未提交或未审核，请维护，完成后即可投保',
      simpleMode: {
        confirm: thisDialog => {
          thisDialog.changeBtnLoadingStatus('confirm', true);
          thisDialog.handleHide();
          this.props.closeTab();
          browserHistory.pushWithQuery({ pathname: '/Company/insuredInfo', query: { tabName: '投保资料' } });
        },
        cancel: thisDialog => {
          thisDialog.handleHide();
          this.props.closeTab();
          this.props.redirectRouter({ pathname: '/Operate/carStowage' }, true);
        },
      },
      simpleText: {
        confirm: '前去维护',
        cancel: '取消',
      },
    }).show();
  };

  closeCallback = () => {
    if (this.reloadPage) {
      this.sendReq(this.props);
    } else {
      this.props.closeTab && this.props.closeTab();
      this.handleHide && this.handleHide();
      if (this.redirectConfig && this.props.openTab) {
        this.props.openTab(this.redirectConfig);
      } else {
        this.props.redirectRouter &&
          this.props.redirectRouter({ pathname: this.fromPageUrl || this.redirectUrl }, false, true);
      }
    }
    import('../../../utils/emitter.js').then(({ default: emitter }) => {
      const pickOrderEmitter = emitter.getSpance('picker_order');
      pickOrderEmitter.emit('picker_order_done', this);
    });
  };
  render() {
    return null;
  }
}
